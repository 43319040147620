type FormPropsType = {
    setCity: React.Dispatch<React.SetStateAction<string>>;
    getWeather: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = (props: FormPropsType) => {
   
    return(
        <form onSubmit={props.getWeather}>
            <input type="text" placeholder="都市名" name="city" onChange={e => props.setCity(e.target.value) }/>
            <button type="submit">Get Weather</button>
        </form>
    )
}

export default Form;